import React, { useEffect, useState } from "react";
import classes from "./AddMainServiceModal.module.css";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import {
  addMainServiceApi,
  addSubServiceApi,
  addSubServiceDetailsApi,
  getSubServiceDetailsByIdApi,
  updateServiceInfoDetailsApi,
  updateSubServiceDetailsApi,
} from "../../Data/Api";
import { toast } from "react-toastify";
import Loader from "../Loading-States/Loader";
import AddIcon from "@mui/icons-material/Add";

function ViewSubServiceDetailsModal({
  viewSubServiceDetailsModal,
  closeViewSubServiceDetailsModal,
  subServiceName,
  subServiceId,
}) {
  const handleClose = () => {
    closeViewSubServiceDetailsModal();
  };

  const [serviceName, setServiceName] = useState("");
  //   const [subServiceName, setSubServiceName] = useState("");
  const [database, setDatabase] = useState("");
  const [serviceUrl, setServiceUrl] = useState("");
  const [callbackUrl, setCallbackUrl] = useState("");
  const [price, setPrice] = useState("");
  const [pack, setPack] = useState("");
  const [frontendpath, setFrontendpath] = useState("");
  const [backendpath, setBackendpath] = useState("");
  const [description, setDescription] = useState("");
  const [country, setCountry] = useState("");
  const [operator, setOperator] = useState("");
  const [empId, setEmpId] = useState("");
  const [serverIp, setServerIp] = useState("");
  const [serviceVideoUrl, setServiceVideoUrl] = useState("");
  const [promotionStartDate, setPromotionStartDate] = useState("");
  const [lastPromotionDate, setLastPromotionDate] = useState("");
  const [promotionUrl, setPromotionUrl] = useState("");
  const [callbackRequest, setCallbackRequest] = useState("");
  const [callbackResponse, setCallbackResponse] = useState("");
  const [chargingStatus, setChargingStatus] = useState("");
  const [chargingApi, setChargingApi] = useState("");
  const [chargingRequest, setChargingRequest] = useState("");
  const [chargingResponse, setChargingResponse] = useState("");
  const [freeTrial, setFreeTrial] = useState("");
  const [freeTrialDays, setFreeTrialDays] = useState("");
  const [loader, setLoader] = useState("none");

  const hitApi = async () => {
    try {
      setLoader("block");
      let token = localStorage.getItem("userToken");
      const response = await axios.post(
        `${getSubServiceDetailsByIdApi}`,
        {
          subServiceName: subServiceName,
          serviceId: subServiceId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoader("none");

      console.log(response, "r");
      if (response?.data?.result.length > 0) {
        setServiceName(response?.data?.result[0].serviceName);
        setDatabase(response?.data?.result[0].database);
        setServiceUrl(response?.data?.result[0].serviceUrl);
        setCallbackUrl(response?.data?.result[0].callbackUrl);
        setPrice(response?.data?.result[0].price);
        setPack(response?.data?.result[0].pack);
        setFrontendpath(response?.data?.result[0].frontendpath);
        setBackendpath(response?.data?.result[0].backendpath);
        setDescription(response?.data?.result[0].description);
        setCountry(response?.data?.result[0].country);
        setOperator(response?.data?.result[0].operator);
        setEmpId(response?.data?.result[0].empId);
        setServerIp(response?.data?.result[0].serverIp);
        setServiceVideoUrl(response?.data?.result[0].serviceVideoUrl);
        setPromotionStartDate(response?.data?.result[0].promotionStartDate);
        setLastPromotionDate(response?.data?.result[0].lastPromotionDate);
        setPromotionUrl(response?.data?.result[0].promotionUrl);
        setCallbackRequest(response?.data?.result[0].callbackRequest);
        setCallbackResponse(response?.data?.result[0].callbackResponse);
        setChargingStatus(response?.data?.result[0].chargingStatus);
        setChargingApi(response?.data?.result[0].chargingApi);
        setChargingRequest(response?.data?.result[0].chargingRequest);
        setChargingResponse(response?.data?.result[0].chargingResponse);
        setFreeTrial(response?.data?.result[0].freeTrial);
        setFreeTrialDays(response?.data?.result[0].freeTrialDays);
      } 
      else {
        toast.error("Details of Sub Service Not Added Yet!");
        handleClose();
      }
    } catch (error) {
      setLoader("none");

      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  useEffect(() => {
    if (viewSubServiceDetailsModal) {
      hitApi();
    }
  }, [viewSubServiceDetailsModal]);

  return (
    <>
      <Modal
        open={viewSubServiceDetailsModal}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box className={classes.modalStyle}>
          <Loader value={loader} />
          <div className={classes.form}>
            <TextField
              id="serviceName"
              label="Service Name"
              value={serviceName}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            />
            <TextField
              id="subServiceName"
              label="Sub Service Name"
              value={subServiceName}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              sx={{ width: "100%" }}
            />

            {/* <TextField
              id="database"
              label="Database"
              value={database}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              sx={{ width: "100%" }}
            /> */}

            <TextField
              id="serviceUrl"
              label="Service Url"
              value={serviceUrl}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              sx={{ width: "100%" }}
            />

            {/* <TextField
              id="callbackUrl"
              label="Callback Url"
              value={callbackUrl}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              sx={{ width: "100%" }}
            /> */}

            <TextField
              id="price"
              label="Price"
              type="number"
              value={price}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              sx={{ width: "100%" }}
            />

            <TextField
              id="pack"
              label="Pack"
              type="number"
              variant="outlined"
              value={pack}
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            />

            {/* <TextField
              id="frontendpath"
              label="Frontend Path"
              variant="outlined"
              value={frontendpath}
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            />
            <TextField
              id="backendpath"
              label="Backend Path"
              value={backendpath}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              sx={{ width: "100%" }}
            /> */}
            <TextField
              id="description"
              label="Description"
              value={description}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              sx={{ width: "100%" }}
            />
            <TextField
              id="country"
              label="Country"
              variant="outlined"
              value={country}
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            />
            <TextField
              id="operator"
              label="Operator"
              variant="outlined"
              value={operator}
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            />
            {/* <TextField
              id="empId"
              label="empId"
              value={empId}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              sx={{ width: "100%" }}
            /> */}
            {/* <TextField
              id="serviceVideoUrl"
              label="Service Video Url"
              variant="outlined"
              value={serviceVideoUrl}
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            /> */}
            {/* <TextField
              id="serverIp"
              label="Server Ip"
              variant="outlined"
              value={serverIp}
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            /> */}

            <TextField
              id="promotionUrl"
              label="Promotion Url"
              variant="outlined"
              value={promotionUrl}
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            />
            <TextField
              id="promotionStartDate"
              label="Promotion Start Date"
              variant="outlined"
              value={promotionStartDate}
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            />
            <TextField
              id="lastPromotionDate"
              label="Last Promotion Date"
              variant="outlined"
              value={lastPromotionDate}
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            />
            {/* <TextField
              id="callbackRequest"
              label="Callback Request"
              variant="outlined"
              value={callbackRequest}
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            />
            <TextField
              id="callbackResponse"
              label="Callback Response"
              variant="outlined"
              value={callbackResponse}
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            /> */}

            {/* <TextField
              id="chargingStatus"
              label="Charging Status"
              variant="outlined"
              value={chargingStatus}
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            />
            <TextField
              id="chargingApi"
              label="Charging Api"
              variant="outlined"
              value={chargingApi}
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            />
            <TextField
              id="chargingRequest"
              label="Charging Request"
              variant="outlined"
              value={chargingRequest}
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            />
            <TextField
              id="chargingResponse"
              label="Charging Response"
              variant="outlined"
              value={chargingResponse}
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            /> */}

            <TextField
              id="freeTrail"
              label="Free Trial"
              variant="outlined"
              value={freeTrial}
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            />

            <TextField
              id="freeTrailDays"
              label="Free Trail Days"
              variant="outlined"
              value={freeTrialDays}
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            />
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ViewSubServiceDetailsModal;
