import React, { useEffect, useState } from "react";
import classes from "./ServiceAdministration2.module.css";
import {
  fetchClientServices,
  fetchClientSubServicesApi,
  getServiceInfoDetailsApi,
  getSubServiceDetailsApi,
  sendDataApi,
} from "../../Data/Api";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import PostSecure from "../../Request/PostSecure";
import Loader from "../../NewComponents/Loading-States/Loader";
import NewSidebarAdmin from "../../NewComponents/Sidebar/NewSidebarAdmin";
import NewHeaderAdmin from "../../NewComponents/Header/NewHeaderAdmin";
import { Dropdown } from "primereact/dropdown";
import TitleHeader from "../../NewComponents/Header/TitleHeader";
import { Button, IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ServiceModal from "../../NewComponents/ServiceModal/ServiceModal";
import AddMainServiceModal from "../../NewComponents/ServiceModals/AddMainServiceModal";
import AddSubServiceModal from "../../NewComponents/ServiceModals/AddSubServiceModal";
import AddSubServiceDetailsModal from "../../NewComponents/ServiceModals/AddSubServiceDetailsModal";
import {
  JsonView,
  allExpanded,
  darkStyles,
  defaultStyles,
} from "react-json-view-lite";

const ServiceAdministration2 = () => {
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState("");
  const [clientForDropdown, setClientForDropdown] = useState("");

  const [subServices, setSubServices] = useState([]);
  // const [subService, setSubService] = useState("");

  const [sidebarHide, setSidebarHide] = useState(() =>
    localStorage.getItem("sidebar")
      ? JSON.parse(localStorage.getItem("sidebar"))
      : false
  );
  const sidebarHandler = () => {
    localStorage.setItem("sidebar", JSON.stringify(!sidebarHide));
    setSidebarHide(JSON.parse(localStorage.getItem("sidebar")));
  };

  //to start on load
  useEffect(() => {
    const clients_variable = JSON.parse(localStorage.getItem("clients"));
    setClients(clients_variable);
    setClient(clients_variable[0]?.id);
    setClientForDropdown(clients_variable[0]);
    gettingClientServices(clients_variable[0].id);
  }, []);

  //Hook to store services
  const [services, setServices] = useState([]);

  //Hook to store biggest value
  const [biggest, setBiggest] = useState(0);
  const [biggestRenewal, setBiggestRenewal] = useState(0);
  const [biggestSubscription, setBiggestSubscription] = useState(0);

  async function gettingClientServices(clientId) {
    try {
      setLoader("block");
      //fetch Services of that client and store in localStorage;
      // gettingServices() function call;
      let token = localStorage.getItem("userToken");
      console.log(token, "tt");
      let headers = { Authorization: "Bearer " + token };
      const res = await axios.post(
        `${fetchClientServices}?clientId=${clientId}`,
        null,
        {
          headers: headers,
        }
      );
      console.log(res, "res");
      localStorage.setItem("services", JSON.stringify(res?.data?.data));
      gettingServices();
    } catch (error) {
      setLoader("none");
      if (error?.response?.status == 403) {
        toast.error(
          error?.response?.data?.message ||
            error?.data?.message ||
            error?.message ||
            error
        );
        setTimeout(() => {
          window.location.href = "/";
        }, 1500);
      } else {
        toast.error(
          error?.response?.data?.message ||
            error?.data?.message ||
            error?.message ||
            error
        );
      }
    }
  }

  //Getting Services
  const gettingServices = () => {
    let services2 = JSON.parse(localStorage.getItem("services"));
    // let filteredServices = services2.filter(
    //   (data) => data?.country == countryName
    // );
    setServices(services2);
    getDataFromBackend(services2[0]?.serviceName, services2);
  };

  //Hook to store dates
  const [dates, setDates] = useState({
    to: moment(new Date()).format("yyyy-MM-DD"),
    from: moment().subtract(30, "days").format("yyyy-MM-DD"),
  });

  const [startDateForCalendar, setStartDateForCalendar] = useState(
    moment().subtract(30, "days").toDate()
  );
  const [endDateForCalendar, setEndDateForCalendar] = useState(new Date());

  const [service, setService] = useState("");
  const [responseService, setResponseService] = useState("");

  const [serviceId, setServiceId] = useState(null);

  const [subServiceDetails, setSubServiceDetails] = useState([]);

  const fetchSubServiceDetails = async (data) => {
    try {
      let token = localStorage.getItem("userToken");
      const response = await axios.post(
        `${getSubServiceDetailsApi}`,
        {
          subServiceInfo: data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response, "response...");
      setSubServiceDetails(response?.data?.result);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const fetchSubServices = async (serviceid) => {
    try {
      setServiceId(serviceid);
      let token = localStorage.getItem("userToken");

      let headers = { Authorization: "Bearer " + token };
      const response = await axios.post(
        `${fetchClientSubServicesApi}?mainServiceId=${serviceid}`,
        null,
        {
          headers: headers,
        }
      );

      setSubServices(response?.data?.data?.dataArray);
      setSubService(null);
      setSubServiceDetails([]);
      // setSubService(() => response?.data?.data?.dataArray[0]?.subServiceName);
      return response?.data?.data?.dataArray[0]?.subServiceName;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
      if (error?.response?.status == 403) {
        toast.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error?.message ||
            error
        );
        setTimeout(() => {
          window.location.href = "/";
        }, 1500);
      }
    }
  };

  //Method to get data from Backend
  const getDataFromBackend = async (service2, allServices) => {
    setService(service2);
    const serviceid = allServices.filter(
      (data) => data?.serviceName == service2
    );

    if (serviceid.length > 0) {
      const subServiceValue = await fetchSubServices(serviceid[0]?.id);
      let data = {
        from: dates.from,
        to: dates.to,
        serviceName: service2,
        subServiceName: subServiceValue,
      };

      let promise = PostSecure(sendDataApi, data);
      promise
        .then((e) => {
          handleDataResponse(e);
        })
        .catch((err) => toast.error(err?.data?.message || err?.message || err));
    }
  };

  //Hook to store data
  const [data, setData] = useState([]);

  //Method to handle response
  const handleDataResponse = (e) => {
    if (e.response === "error") {
      setLoader("none");
      if (e?.error?.response?.status == 403) {
        toast.error(e.error?.response?.data?.message || e.error?.message);
        setTimeout(() => {
          window.location.href = "/";
        }, 1500);
      } else {
        toast.error(e.error?.response?.data?.message || e.error?.message);
      }
    } else {
      setLoader("none");
      const dataFromBackend = e.data;
      const dataDateManupulate = dataFromBackend.map((dataItem) => {
        return {
          id: dataItem?.id,
          misDate: dataItem?.misDate.substring(0, 10),
          totalBase: dataItem?.totalBase,
          totalActiveBase: dataItem?.totalActiveBase,
          subscriptions: dataItem?.subscriptions,
          unsubscriptions: dataItem?.unsubscriptions,
          renewalsRevenue: dataItem?.renewalsRevenue,
          renewals: dataItem?.renewals,
          subscriptionRevenue: dataItem?.subscriptionRevenue,
          totalRevenue: dataItem?.totalRevenue,
          totalRevenueAccumulated: dataItem?.DailyIncreaseAccumulated,
        };
      });

      const dataLimit = dataDateManupulate.slice(0, 33);
      setData(dataLimit.reverse());

      const biggestValue = Math.max.apply(
        Math,
        dataLimit.map(function (dataItem) {
          return dataItem.totalRevenue;
        })
      );
      setResponseService(e.service);
      setBiggest(biggestValue);
      const biggestValueRenewal = Math.max.apply(
        Math,
        dataLimit.map(function (dataItem) {
          return dataItem.renewalsRevenue;
        })
      );
      setBiggestRenewal(biggestValueRenewal);
      const biggestValueSubscription = Math.max.apply(
        Math,
        dataLimit.map(function (dataItem) {
          return dataItem.subscriptionRevenue;
        })
      );
      setBiggestSubscription(biggestValueSubscription);
    }
  };

  //Hook to store loader div state
  const [loader, setLoader] = useState("block");

  const handleServiceChange = (service) => {
    setLoader("block");
    let allServices = JSON.parse(localStorage.getItem("services"));
    getDataFromBackend(service, allServices);
  };

  const handleClientChange = (client) => {
    let username = client?.username;
    const clients_variable = JSON.parse(localStorage.getItem("clients"));
    const countries_variables = clients_variable?.filter(
      (data) => data?.username == username
    );
    setClientForDropdown(client);
    setClient(client?.id);
    gettingClientServices(client?.id);
  };

  const [addMainServiceModal, setAddMainServiceModal] = useState(false);
  const [addSubServiceModal, setAddSubServiceModal] = useState(false);

  const [subService, setSubService] = useState(null);

  const closeMainServiceModal = () => {
    setAddMainServiceModal(false);
  };

  const closeSubServiceModal = () => {
    setAddSubServiceModal(false);
  };

  const handleSubServiceClick = (sub_data) => {
    // hit the api to get the data of subservice details

    fetchSubServiceDetails(sub_data);
    setSubService(sub_data);
    // setSubService(id);
  };

  console.log(serviceId, "sid");
  console.log(subService, "ss");

  const [addSubServiceDetailsModal, setAddSubServiceDetailsModal] = useState({
    state: false,
    type: null,
    data: null,
  });

  const closeSubServiceDetailsModal = () => {
    setAddSubServiceDetailsModal({
      state: false,
      type: null,
      data: null,
    });
  };

  return (
    <>
      <Loader value={loader} />
      <ToastContainer />
      <div className={`${classes.main} ${sidebarHide && classes.short}`}>
        <div className={`${classes.sidebar} ${sidebarHide && classes.short}`}>
          <div
            className={`${classes.sidebar_header} ${
              sidebarHide && classes.short
            }`}
          >
            <img
              src="/assets/images/logo1.png"
              alt="Revenue portal"
              className={classes.sidebar_logo}
            />
            <h3 className={classes.dashboard_text}>Dashboard</h3>
          </div>
          <div className={classes.sidebar_icon}>
            <div className={classes.circle} onClick={sidebarHandler}>
              {sidebarHide ? (
                <i
                  className={`fa-solid fa-arrow-right ${classes.arrow_icon}`}
                ></i>
              ) : (
                <i
                  className={`fa-solid fa-arrow-left ${classes.arrow_icon}`}
                ></i>
              )}
            </div>
          </div>
          <NewSidebarAdmin highlight={5} sidebarHide={sidebarHide} />
        </div>
        <div className={classes.container}>
          <NewHeaderAdmin service={responseService} highlight={5} />
          <div className={classes.sub_container}>
            <form className={classes.form}>
              <div className={classes.client}>
                <Dropdown
                  value={clientForDropdown}
                  onChange={(e) => handleClientChange(e.value)}
                  options={clients?.map((client) => ({
                    label: client?.clientName,
                    value: client,
                  }))}
                  placeholder="Select a Client"
                  style={{ width: "100%" }}
                />
              </div>
            </form>

            <div className={classes.box}>
              <h3 className={classes.box_title}>Main Services</h3>
              <Tooltip
                sx={{ padding: "0px" }}
                title="Add New Main Service"
                onClick={() => setAddMainServiceModal(true)}
              >
                <IconButton aria-label="Add New Main Service">
                  <AddIcon color="primary" />
                </IconButton>
              </Tooltip>
            </div>

            <div className={classes.flex_container}>
              {services.map((service2, i) => {
                return (
                  <div key={i} className={classes.flex_item}>
                    <Button
                      className={classes.btn}
                      onClick={() => handleServiceChange(service2?.serviceName)}
                      variant={
                        service2?.serviceName === service
                          ? "contained"
                          : "outlined"
                      }
                      // color="error"
                      size="medium"
                    >
                      {service2?.serviceName}
                    </Button>
                  </div>
                );
              })}
            </div>

            <div className={classes.box}>
              <h3 className={classes.box_title}>({service}) Sub-Services</h3>
              <Tooltip
                sx={{ padding: "0px" }}
                title="Add New Sub Service"
                onClick={() => setAddSubServiceModal(true)}
              >
                <IconButton aria-label="Add New Sub Service">
                  <AddIcon color="primary" />
                </IconButton>
              </Tooltip>
            </div>

            <div className={classes.flex_container}>
              {subServices.map((service, i) => {
                if (service.subServiceName !== "All") {
                  return (
                    <div key={i} className={classes.flex_item}>
                      <Button
                        variant={
                          subService?.id === service.id
                            ? "contained"
                            : "outlined"
                        }
                        // color="error"
                        size="medium"
                        onClick={() => handleSubServiceClick(service)}
                      >
                        {service.subServiceName}
                      </Button>
                    </div>
                  );
                }
              })}
            </div>

            {subService && (
              <>
                <div className={classes.box}>
                  <h3 className={classes.box_title}>
                    {subService?.subServiceName}
                  </h3>
                </div>

                {subServiceDetails.length === 0 ? (
                  <button
                    className={classes.add_btn}
                    onClick={() =>
                      setAddSubServiceDetailsModal({
                        state: true,
                        type: "add",
                        data: null,
                      })
                    }
                  >
                    Add Sub Service Details
                    <AddIcon />
                  </button>
                ) : (
                  <>
                    <button
                      className={classes.add_btn}
                      onClick={() =>
                        setAddSubServiceDetailsModal({
                          state: true,
                          type: "edit",
                          data: subServiceDetails[0],
                        })
                      }
                    >
                      Edit Details
                      <AddIcon />
                    </button>
                    <JsonView
                      data={subServiceDetails[0]}
                      shouldExpandNode={allExpanded}
                      style={darkStyles}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <AddMainServiceModal
        addMainServiceModal={addMainServiceModal}
        closeMainServiceModal={closeMainServiceModal}
        clientId={client}
        hitApi={gettingClientServices}
      />
      <AddSubServiceModal
        service={service}
        serviceId={serviceId}
        addSubServiceModal={addSubServiceModal}
        closeSubServiceModal={closeSubServiceModal}
      />
      <AddSubServiceDetailsModal
        addSubServiceDetailsModal={addSubServiceDetailsModal}
        closeSubServiceDetailsModal={closeSubServiceDetailsModal}
        serviceName={subService?.serviceName}
        subServiceName={subService?.subServiceName}
        subServiceInfo={subService}
        fetchSubServiceDetails={fetchSubServiceDetails}
      />
    </>
  );
};

export default ServiceAdministration2;
